body {
  margin: 0;
  font-family: 'Nunito';
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  background: #FFF4F3;
}

h2{
  font-family: 'Nunito';
  font-weight: 200; /* ExtraLight */
  font-size: 2em;
}

h4 {
  font-family: 'Nunito';
  font-weight: 200; /* ExtraLight */
  font-size:  1.5em;
}

p, a, li, span, button {
  font-family: 'Nunito';
  font-weight: 300;
}

h1, h3 {
  font-family: 'Darker Grotesque';
  font-weight: 800; /* ExtraBold */
}

h3 {
  font-size: 1.5em;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

::selection {
  background: #dbc9d6;
}

::-moz-selection {
  background: #dbc9d6;
}