.landing-page {
  font-family: 'Arial, sans-serif';
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: transparent;
  height: 40px;
}

.header .logo img {
  height: 25px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #0632AF;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 2;
  color: #0632AF;
}

.dropdown-menu a {
  margin: 5px 0;
  text-decoration: none;
  color: #0632AF !important;
}

.hero-section {
  align-items: center;
  padding: 50px;
  height: calc(98vh - 180px);
}

.hero-content {
  max-width: 37%;
  margin: 10%;
  color: #FFF4F3;
  position: absolute;
  left: 0px;
  top:10%;
  z-index: 1;
}

.hero-content h1 {
  font-size: 2.5em;
  line-height: 1;
}

.hero-content p {
  font-size: 1.1em;
  margin: 20px 0;
  line-height: 2;
}

.hero-content button {
  padding: 10px 50px;
  margin: 20px;
  cursor: pointer;
  font-size: 0.9em;
  background: transparent;
  color: #FFF4F3;
  border: 1px solid;
  border-radius: 50px;
  border-color: #FFF4F3;
  transition-duration: 0.1s;
}

.hero-content button:hover {
  background: #FFF4F3;
  border-color: #FFF4F3;
  color: #c2a1ba;
}

.hero-experience {
  width: 100%;
  height: 100%;
}

.experience-canvas {
    width: 60% !important;
    height: 80% !important;
    z-index: 0;
    touch-action: none;
    will-change: transform;
    transform: translate(70%, 10%);
}

.htmlScreen iframe
{
    border: none;
    border-radius: 2px;
}

.hero-social {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
/*  align-items: center;*/
  padding-left: 20px;
}

.hero-social a {
  color: #FFF4F3;
  margin: 0 10px;
}

.hero-container {
  background-image: url("../public/hs_backgroud.png");
  background-size: cover;
  border-radius: 30px;
  margin: 1vh;
}

@media (max-width: 768px) {
  .hero-content {
    max-width: 100%;
  }
  .experience-canvas {
    display: none;
  }
}

.features-section {
  padding: 5% 10%;
  color: #0632AF;
}

.features-section h2 {
  margin-bottom: 20px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 5%;
  padding: 3%;
  padding-bottom: 5%;
}

.feature-card {
  padding: 5%;
  position: relative;
}

.disabled {
  color: #828181;
}

.feature-card .disabled {
  font-size: 1.5em;
}

.coming-soon {
  position: absolute;
  background: #EE9487;
  color: #FFF4F3;
  padding: 3px 13px;
  border-radius: 50px;
  font-size: 0.9em;
  margin: 2%;
  bottom: 0px;
  transform: translateX(70%);
}

.feature-icon-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.feature-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}

.feature-icon {
  background: #0632AF;
  border-radius: 100px;
  height: 100px;
  width: 100px;
  padding: 2%;
}

.feature-icon img {
  height: 100%;
  width: 100%;
  border-radius: 50px;
}

/*.feature-icon:hover {
  box-shadow: 3px 6px 18px 6px #FEE2DE;
  outline: solid 3px #EE9487;
}*/

@media (max-width: 768px) {
  .features-grid {
    max-width: 100%;
    display: block;
  }
}

.about {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  background: #FEE2DE;
  color: #0632AF;
  margin: 3em;
  border-radius: 50px;
}

.about-content {
  max-width: 50%;
}

.about-image {
  padding: 2%;
}

.about-image img {
  height: 100%;
  width: 100%;
  border-radius: 50px;
}

@media (max-width: 768px) {
  .about {
    max-width: 100%;
    flex-direction: column-reverse;
    padding: 0;
    margin: 1.5em;
  }
  .about-content {
    max-width: 100%;
    padding: 2em;
    padding-bottom: 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EE9487;
  margin: 2em;
}

.footer .social-media-icon {
  margin: 10px 50px;
}

.footer .social-media-icon a {
  color: #EE9487;
  text-decoration: none;
}

.footer-description {
  font-size: 0.8em;
  max-width: 600px;
  margin: auto;
}

.footer-section {
  margin: 20px 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 2em;
}

.pagination-link {
  color: #0632AF;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #0632AF;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.pagination-link:hover {
  background-color: #0632AF;
  color: #FEE2DE;
}

