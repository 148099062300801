.referents-page-container {
  text-align: center;
  color: #0632AF;
}

.intro p{
  padding: 0% 15%;
}

.referents-container{
  margin: 1.5em;
}

.referents-cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  margin-top: 3em;
}

.referents-card-wrapper {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  scroll-snap-align: center;
}

@media (max-width: 1200px) {
  .referents-card-wrapper {
    flex-basis: 50%;
  }
}

.referents-card {
  background-color: white;
  margin: 1em;
  height: 350px;
  border-radius: 20px;
  box-shadow: 3px 6px 18px 6px #FEE2DE;
}

.referents-card:hover {
  background-color: #FEE2DE;
  box-shadow: none;
}

.referents-card img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 30px;
}

.referents-card h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 0px;
}

.referents-card p {
  font-size: 0.9rem;
  padding: 0 10px;
}

a {
  text-decoration: none; 
  color: inherit;
}

.referents-cards-container::-webkit-scrollbar {
  display: none; /* Ocultar scrollbar */
}

@media (max-width: 768px) {
  .referents-cards-container {
    display: block;
  }
  .referents-container{
    margin: 1.5%;
  }
  .referents-card-content {
    margin: 1.5%;
  }
}