
.quiz-page-container {
	overflow: hidden; /* Evita el scroll */
	color: #0632AF;
  background-image: url("../../../public/pattern.png");
  background-size: cover;
  height: 100vh;
}

.quiz-container {
  background: rgba(255, 244, 243, 0.8);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 62.5vh;
  width: calc(100vw - 12%);
  text-align: center;
  margin: 0% 3%;
  padding: 3%;
}

.question-container {
  padding-bottom: 5vh;
}

.question-slide {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h2 {
  margin: 2em;
}

.answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.answer-btn {
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #dbc9d6;
  color: #0632AF;
  margin: 0em 20%;
  border: none;
}

.answer-btn:hover {
  outline: 2px solid #0632AF;
  outline-offset: -2px;
}

.answer-btn.disabled {
  display: none;
}

.fact {
  margin-top: 20px;
  font-size: 1rem;
  padding: 0 10%;
}

.intro-slide {
  padding: 0px 10%;
}

.intro-slide button, .fact button {
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #0632AF;
  color: #FEE2DE;
  border-radius: 50px;
  border: none;
  padding-inline: 3em;
}

.progress-bar {
  display: flex;
  gap: 8px;
  margin-top: 20px;
  justify-content: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: lightgray;
  transition: background-color 0.3s;
  background-color: #dbc9d6;
}

.dot.active {
  background-color: #0632AF;
}

@media (max-width: 768px) {
  .question-container h2 {
    font-size: 1.5em;
  }
}