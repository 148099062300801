.info-page-container {
  text-align: center;
  color: #0632AF;
}

.intro p{
  padding: 2% 15%;
}

.info-box {
  background: #FEE2DE;
  border-radius: 50px;
  margin: 7% 3%;
  padding: 3%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.info-box div {
  padding: 0% 3%;
}

.info-box-text {
  width: 60%;
}

.info-box-img {
  width: 40%;
}

.info-box img {
  width: 100%;
  padding: 2%;
}

@media (max-width: 768px) {
  .info-box {
    flex-direction: column !important;
  }

  .info-box-text {
    width: 80%;
  }

  .info-box-img {
    width: 80%;
  }
}

.careers-container {
  padding: 1%;
}

.careers-section {
  display: flex;
  padding: 2%;
  height: 44em;
  font-size: 15px;
}

.careers-section h3{
  margin: 0;
}

.career-selector {
  display: flex;
  flex-direction: column;
  margin-right: 0.5em;
  width: 10em;
  justify-content: space-evenly;
  z-index: 1;
}

.career-button {
  background-color: #ffffff;
  padding: 1em;
  margin-bottom: 0.5em;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border-color: transparent;
/*  transition: all 0.3s ease;*/
  color: #0632AF;
  font-size: 1em;
  height: -webkit-fill-available;
}

.career-button.selected {
  background-color: #fee2de;
}

.career-button:hover {
  background-color: #dbc9d6;
}

.career-info-card {
  background-color: #fff;
  border-radius: 30px;
  padding: 2.5em;
  flex: 1;
  box-shadow: 3px 6px 18px 6px #FEE2DE;
}

.career-main{
  display: flex;
}

.text-column{
  width: 180%;
  margin-left: 5%;
}

.img-column img{
  width: 100%;
}

.career-details {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.career-details div{
  flex-grow: 1;
  flex-basis: 0;
}

.career-details ul{
  text-align: left;
  padding: 1em;
  padding-bottom: 0;
  margin: 0;
}

.career-details h4{
  margin-top: 0.5em;
  margin-bottom: 0;
}

.career-rating {
/*  text-align: left;*/
/*  margin-top: 4em;*/
  margin-left: 1em;
  border: 1px solid #0632AF;
  border-radius: 20px;
  padding: 0.5em;
  align-items: center;
}

.career-rating p {
  margin: 5px 0;
}

.stars img {
  width: 20px; /* Ajusta el tamaño según sea necesario */
  height: auto;
  margin-right: 2px; /* Espacio entre las estrellas */
}


@media (max-width: 1200px) {
  .careers-section {
    height: 50em;
  }
}

@media (max-width: 1100px) {
  .careers-section {
    height: 100%;
    display: block;
  }
  .career-selector {
/*    display: none;*/
/*    flex-direction: row;*/
    width: 100%;
  }
  .career-main {
    flex-direction: column-reverse;
  }
  .text-column {
    width: 100%;
    margin-left: 0;
  }
  .career-details {
    display: block;
  }
  .career-details div {
    margin-top: 2em;
  }
  .career-rating div{
    margin-top: 0;
  }
}

.hobby-container{
  margin: 2.5em;
  margin-top: 6em;
}

.company-container{
  margin: 1.5em;
  margin-top: 6em;
}

.hobby-cards-container{
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
/*  margin: 3em;*/
  margin-top: 3em;
}

.hobby-cards-row{
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -15px; /* Permite que las cards desaparezcan hacia los márgenes */
  padding-bottom: 20px;
}

.company-cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
}

.hobby-card{
  flex: 0 0 250px;
  height: 370px;
  margin: 0 15px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 3px 6px 18px 6px #FEE2DE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  text-align: center;
  scroll-snap-align: center;
}

.company-card {
  flex-basis: 25%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  scroll-snap-align: center;
}

.company-card-content {
  border-radius: 20px;
  background-color: #FEE2DE;
  height: 100%;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;;
}

.hobby-card img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 6px;
  transform: scale(1.5);
}

.company-card img{
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin-top: 2em;
}

.hobby-card h3, .company-card h3 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.hobby-card p, .company-card p {
  font-size: 16px;
  padding: 0 10px;
  margin-top: 5px;
}

.hobby-cards-container::-webkit-scrollbar, .company-cards-container::-webkit-scrollbar {
  display: none; /* Ocultar scrollbar */
}

.tag-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-bottom: 1.5em;
  justify-content: center;
}

.tag-button {
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #0632AF;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tag-button.selected {
  background-color: #dbc9d6;
  color: #0632AF;
  box-shadow: 3px 6px 18px 6px #FEE2DE;
}

.tag-button:hover {
  color: #0632AF;
  box-shadow: 3px 6px 18px 6px #FEE2DE;
}

.company-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  margin-bottom: 1em;
}

.company-tag {
  background-color: #FFF4F3;
  color: #0632AF;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.9em;
  white-space: nowrap;
}

.scroll-button {
  background-color: rgba(0, 0, 0, 0);
  color: #0632AF;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.scroll-button:hover {
  color: #dbc9d6;
}

@media (max-width: 768px) {
  .company-cards-container {
    display: block;
  }
  .hobby-container{
    margin: 3%;
  }
  .company-container{
    margin: 1.5%;
  }
  .company-card-content {
    margin: 1.5%;
  }
}

/* Style for modal overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

/* Style for modal content box */
.modal-content {
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0px;
  border-radius: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style for close button in the modal */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 36px;
  cursor: pointer;
  color: white;
}

/* Style to make the image clickable */
.clickable-image {
  cursor: pointer;
  border-radius: 30px;
}

.clickable-image:hover {
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 3px 6px 18px 6px #dbc9d6;
}
